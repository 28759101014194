import React from 'react';
import { useParams } from 'react-router-dom';

const BlogPost: React.FC = () => {
  const { id } = useParams<{ id: string }>();  // Obtém o ID do post pela URL

  // Aqui você buscaria o post real pelo ID
  const post = { title: `Post ${id}`, content: 'Conteúdo completo do post...' };

  return (
    <div className="blog-post">
      <h1>{post.title}</h1>
      <p>{post.content}</p>
    </div>
  );
};

export default BlogPost;
