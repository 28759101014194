import React from 'react';
import { Link } from 'react-router-dom';

const BlogList: React.FC = () => {
  const posts = [
    { id: 1, title: 'Post 1', description: 'Descrição do post 1' },
    { id: 2, title: 'Post 2', description: 'Descrição do post 2' },
    // Adicione mais posts aqui
  ];

  return (
    <div className="blog-list">
      {posts.map(post => (
        <div key={post.id} className="blog-list-item">
          <h2>{post.title}</h2>
          <p>{post.description}</p>
          <Link to={`/blog/${post.id}`}>Ler mais</Link>
        </div>
      ))}
    </div>
  );
};

export default BlogList;
