import React from "react";
import "./Footer.css";

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <p>© 2024 UpdateCommit. Todos os direitos reservados.</p>
      <ul className="footer-links">
        <li><a href="#privacy">Privacidade</a></li>
        <li><a href="#terms">Termos de Serviço</a></li>
        <li><a href="#contact">Contato</a></li>
      </ul>
      <a href="https://storyset.com/people">People illustrations by Storyset</a>
    </footer>
  );
};

export default Footer;
