import React from 'react';
import './CallToAction.css';
import imgCTA from '../../assets/images/vetores/Programming-amico.png';

const CallToAction: React.FC = () => {
  return (
    <section className="cta-section">
      <div className="cta-content">
        <div className="cta-text">
          <h2>Fale Conosco e Transforme Seu Negócio!</h2>
          <p>
            Entre em contato com nossos especialistas para descobrir como podemos
            impulsionar o crescimento da sua empresa com soluções em programação, automação e gestão de tráfego.
          </p>
          <a href="https://wa.me/xxxxxxxxxx" className="cta-button">Entre em Contato</a>
        </div>
        <div className="cta-image">
          <img src={imgCTA} alt="Fale Conosco" />
        </div>
      </div>
    </section>
  );
};

export default CallToAction;
