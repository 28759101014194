import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header/Header';
import BlogList from './components/Blog/BlogList';
import BlogPost from './components/Blog/BlogPost';
import WhatsAppButton from './components/WhatsAppButton/WhatsAppButton';
import Footer from './components/Footer/Footer';
import Home from './Pages/Home';
import FreeSolutions from './Pages/FreeSolutions';
import WhatsAppLinkGenerator from './free-tools/WhatsAppLinkGenerator/WhatsAppLinkGenerator';
import QRCodeGenerator from './free-tools/QRCodeGenerator/QRCodeGenerator'; // Importando o novo QRCodeGenerator
import About from './Pages/About';

const App: React.FC = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/free-solutions" element={<FreeSolutions />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog" element={<BlogList />} />
        <Route path="/blog/:id" element={<BlogPost />} />
        
        {/* Adicionando as rotas para as ferramentas gratuitas */}
        <Route path="/free-tools/whatsapp-link-generator" element={<WhatsAppLinkGenerator />} />
        <Route path="/free-tools/qr-code-generator" element={<QRCodeGenerator />} />
      </Routes>
      <WhatsAppButton />
      <Footer />
    </Router>
  );
};

export default App;
