import React, { useState } from 'react';
import { QRCodeCanvas } from 'qrcode.react'; // Use a versão correta para renderização de QRCode
import './QRCodeGenerator.css';

const QRCodeGenerator: React.FC = () => {
  const [ssid, setSsid] = useState('');
  const [password, setPassword] = useState('');
  const [encryption, setEncryption] = useState('WPA/WPA2');
  const [showPassword, setShowPassword] = useState(false);
  const [qrCodeValue, setQrCodeValue] = useState('');

  const generateQRCode = () => {
    const hiddenFlag = showPassword ? 'true' : 'false';
    const qrCodeData = `WIFI:S:${ssid};T:${encryption};P:${password};H:${hiddenFlag};`;
    setQrCodeValue(qrCodeData);
  };

  return (
    <div className="qrcode-generator">
      <div className="form-section">
        <h2>WiFi QR Code</h2>

        <label>Nome da Rede (SSID)</label>
        <input
          type="text"
          value={ssid}
          onChange={(e) => setSsid(e.target.value)}
          placeholder="SSID"
        />

        <label>Senha</label>
        <input
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Senha"
        />
        <div>
          <input
            type="checkbox"
            checked={showPassword}
            onChange={() => setShowPassword(!showPassword)}
          />
          <label>Mostrar Senha</label>
        </div>

        <label>Criptografia</label>
        <div className="encryption-options">
          <input
            type="radio"
            value="WPA/WPA2"
            checked={encryption === 'WPA/WPA2'}
            onChange={(e) => setEncryption(e.target.value)}
          />
          <label>WPA/WPA2</label>
          <input
            type="radio"
            value="WEP"
            checked={encryption === 'WEP'}
            onChange={(e) => setEncryption(e.target.value)}
          />
          <label>WEP</label>
          <input
            type="radio"
            value="Nenhuma"
            checked={encryption === 'Nenhuma'}
            onChange={(e) => setEncryption(e.target.value)}
          />
          <label>Nenhuma</label>
        </div>

        <button onClick={generateQRCode}>Criar QR Code</button>
      </div>

      <div className="preview-section">
        {qrCodeValue && (
          <>
            <h2>Seu QR Code</h2>
            <QRCodeCanvas value={qrCodeValue} size={200} /> {/* Substituindo pelo QRCodeCanvas */}
            <button className="download-button">Download QR Code</button>
          </>
        )}
      </div>
    </div>
  );
};

export default QRCodeGenerator;
