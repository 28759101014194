import React from 'react';

const About: React.FC = () => {
  return (
    <div className="about-page">
      <h1>Sobre Nós</h1>
      <p>
        A UpdateCommit é uma plataforma inovadora que oferece soluções tecnológicas para pequenas empresas.
      </p>
    </div>
  );
};

export default About;
