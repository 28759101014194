import React from 'react';
import Hero from '../components/Hero/Hero';
import Features from '../components/Features/Features';
import CallToAction from '../components/CallToAction/CallToAction';
import Services from '../components/Services/Services';  // Novo: Adicionando Services
import BlogHighlights from '../components/BlogHighlights/BlogHighlights';  // Novo: Adicionando Blog

const Home: React.FC = () => {
  return (
    <div>
      <Hero />
      <Features />
      <CallToAction />
      <Services /> {/* Novo: Adicionando Services Section */}
    </div>
  );
};

export default Home;
