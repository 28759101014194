import React from 'react';
import { FaWhatsapp } from 'react-icons/fa'; // Importando o ícone do WhatsApp
import './WhatsAppButton.css'; // Importando o CSS para o botão

const WhatsAppButton: React.FC = () => {
  return (
    <a
      href="https://wa.me/5521936185311" // Substitua pelo seu número de WhatsApp
      className="whatsapp-button"
      target="_blank"
      rel="noopener noreferrer"
    >
      <FaWhatsapp className="whatsapp-icon" />
    </a>
  );
};

export default WhatsAppButton;
