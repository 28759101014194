import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './WhatsAppLinkGenerator.css';
import iconewapp from '../../assets/logo/UpCo-Redondo.png';

const WhatsAppLinkGenerator = () => {
  const [countryCode, setCountryCode] = useState('+55');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');
  const [generatedLink, setGeneratedLink] = useState('');
  const [copied, setCopied] = useState(false);

  // Função para gerar o link do WhatsApp
  const generateLink = () => {
    if (!phoneNumber) {
      alert('Por favor, insira o número de telefone.');
      return;
    }

    const formattedMessage = encodeURIComponent(formatMessageForWhatsApp(message));
    const baseLink = `https://wa.me/${countryCode}${phoneNumber}`;
    const fullMessage = message ? `?text=${formattedMessage}` : '';
    const link = `${baseLink}${fullMessage}`;
    setGeneratedLink(link);
    setCopied(false); // Volta o botão para "Copiar Link" ao gerar novo link
  };

  // Função para copiar o link para a área de transferência
  const copyToClipboard = () => {
    navigator.clipboard.writeText(generatedLink);
    setCopied(true); // Altera para "Copiado" após copiar
  };

  const formatMessageForWhatsApp = (input: string) => {
    let formattedMessage = input;

    // Substituir tags <p> e <br> para simular apenas uma nova linha no WhatsApp
    formattedMessage = formattedMessage
      .replace(/<p>/g, '')
      .replace(/<\/p>/g, '\n')
      .replace(/<br>/g, '\n');

    // Remover múltiplas quebras de linha e garantir apenas uma
    formattedMessage = formattedMessage.replace(/\n{3,}/g, '\n\n');

    // Converter negrito, itálico, riscado e monoespaçado
    formattedMessage = formattedMessage
      .replace(/<strong>(.*?)<\/strong>/g, '*$1*')
      .replace(/<em>(.*?)<\/em>/g, '_$1_')
      .replace(/<s>(.*?)<\/s>/g, '~$1~')
      .replace(/<code>(.*?)<\/code>/g, '```$1```');

    return formattedMessage;
  };

  // Função para exibir a mensagem formatada no simulador (lado direito)
  const renderFormattedMessage = (input: string) => {
    return input
      .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
      .replace(/_(.*?)_/g, '<em>$1</em>')
      .replace(/~(.*?)~/g, '<s>$1</s>')
      .replace(/```(.*?)```/g, '<code>$1</code>')
      .replace(/\n/g, '<br />'); // Mantém as quebras de linha
  };

  return (
    <div className="whatsapp-link-generator">
      <div className="generator-left">
        <h1>Gere o seu Link do WhatsApp</h1>

        <div className="input-group-row">
          <div className="input-group">
            <label>Código do país</label>
            <select value={countryCode} onChange={(e) => setCountryCode(e.target.value)}>
              <option value="+55">+55 (Brasil)</option>
              <option value="+1">+1 (USA)</option>
              <option value="+44">+44 (UK)</option>
              <option value="+33">+33 (França)</option>
            </select>
          </div>

          <div className="input-group">
            <label>Número de telefone</label>
            <input
              type="text"
              placeholder="Número de telefone"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>
        </div>

        <div className="input-group">
          <label>Mensagem do WhatsApp</label>
          <ReactQuill
            value={message}
            onChange={setMessage}
            placeholder="Escreva sua mensagem"
            modules={{
              toolbar: [['bold', 'italic', 'strike', 'code']], // Negrito, Itálico, Riscado e Monoespaçado
            }}
            formats={['bold', 'italic', 'strike', 'code']}
          />
        </div>

        <button className="button-link" onClick={generateLink}>
          Gerar Link do WhatsApp
        </button>

        {generatedLink && (
          <div className="actions">
            <button
              className={`copy-button ${copied ? 'copied' : ''}`}
              onClick={copyToClipboard}
            >
              {copied ? 'Copiado' : 'Copiar Link'}
            </button>
            <div className="link-container">
              <input
                type="text"
                className="generated-link-box"
                value={generatedLink}
                readOnly
              />
            </div>
          </div>
        )}
      </div>

      <div className="generator-right">
        <div className="mobile-preview">
          <div className="mobile-header">
            {/* Aqui entra a imagem do favicon e o número de telefone */}
            <img src={iconewapp} alt="Favicon" className="favicon-icon" />
            <span>{countryCode} {phoneNumber || 'Número de Telefone'}</span>
          </div>

          <div className="mobile-screen">
            <div className="whatsapp-message">
              <p>{formatMessageForWhatsApp(message) || 'Digite uma mensagem'}</p>
            </div>
          </div>

          <div className="mobile-footer">
            <input className="footer-input" type="text" placeholder="Digite uma mensagem" />
            <div className="footer-icon">🡪</div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default WhatsAppLinkGenerator;
