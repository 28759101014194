import React from 'react';

const FreeSolutions: React.FC = () => {
  return (
    <div className="free-solutions">
      <h1>Soluções Gratuitas</h1>
      <ul>
        <li>Gestor de Tarefas</li>
        <li>Monitoramento de Tempo</li>
        <li>Agenda Digital</li>
      </ul>
      <p>
        Explore essas ferramentas gratuitas para melhorar sua produtividade e gestão de tempo.
      </p>
    </div>
  );
};

export default FreeSolutions;
