import React, { useState } from 'react';
import './Services.css';
import { FaCode, FaCogs, FaBullhorn, FaChartBar, FaLink, FaFileAlt } from 'react-icons/fa';

// Corrigido: importando a imagem correta e outras imagens de exemplo
import programmingImage from './../../assets/images/vetores/Programming-amico.png'; 
import integrationImage from './../../assets/images/vetores/Software integration-rafiki.png'; // Exemplo de caminho
import trafficImage from './../../assets/images/vetores/Marketing-amico.png'; 
import dashboardImage from './../../assets/images/vetores/Data extraction-amico.png'; 
import automationImage from './../../assets/images/vetores/Software integration-bro.png'; 
import landingPageImage from './../../assets/images/vetores/Website Creator-bro.png'; 

interface Service {
  title: string;
  description: string;
  image: string;
  longDescription: string;
  whyYouNeed: string;
  icon: JSX.Element;
}

const servicesData: Service[] = [
  {
    title: 'Desenvolvimento de Software',
    description: 'Soluções personalizadas para atender às necessidades do seu negócio...',
    longDescription: 'Desenvolvemos soluções completas de software sob medida, desde sistemas de gestão até aplicações web e mobile...',
    whyYouNeed: 'Você precisa de soluções que otimizem e facilitem os processos do seu negócio.',
    image: programmingImage, // Certifique-se que a imagem está no caminho correto
    icon: <FaCode />,
  },
  {
    title: 'Integração de Sistemas',
    description: 'Integre plataformas e otimize seus processos empresariais...',
    longDescription: 'Integramos sistemas para uma comunicação eficiente e segura entre suas plataformas...',
    whyYouNeed: 'Para evitar retrabalho e otimizar a eficiência de suas operações.',
    image: integrationImage, // Exemplo corrigido
    icon: <FaLink />,
  },
  {
    title: 'Gestão de Tráfego Pago',
    description: 'Otimização de campanhas para obter o máximo de ROI...',
    longDescription: 'Gerenciamos e otimizamos campanhas de tráfego pago para garantir o melhor retorno sobre investimento...',
    whyYouNeed: 'Investir em tráfego pago garante mais leads e conversões para o seu negócio.',
    image: trafficImage, // Exemplo corrigido
    icon: <FaBullhorn />,
  },
  {
    title: 'Criação de Dashboards',
    description: 'Visualize seus KPIs em tempo real, tudo que precisa em uma só tela...',
    longDescription: 'Desenvolvemos dashboards que facilitam a visualização de dados em tempo real...',
    whyYouNeed: 'Tomar decisões com base em dados precisos e atuais.',
    image: dashboardImage, // Exemplo corrigido
    icon: <FaChartBar />,
  },
  {
    title: 'Automação de Processos',
    description: 'Otimização de fluxos de trabalho através da automação...',
    longDescription: 'Automatizamos processos para melhorar a eficiência da sua empresa...',
    whyYouNeed: 'Automação ajuda a reduzir custos operacionais e melhorar a produtividade.',
    image: automationImage, // Exemplo corrigido
    icon: <FaCogs />,
  },
  {
    title: 'Criação de Landing Pages',
    description: 'Landing pages otimizadas para conversão, utilizando as melhores práticas de SEO...',
    longDescription: 'Criamos landing pages altamente otimizadas para maximizar a taxa de conversão...',
    whyYouNeed: 'Uma landing page bem otimizada é crucial para campanhas de marketing.',
    image: landingPageImage, // Exemplo corrigido
    icon: <FaFileAlt />,
  },
];

const Services: React.FC = () => {
  const [selectedService, setSelectedService] = useState<Service | null>(null);

  const handleCardClick = (service: Service) => {
    setSelectedService(service);
  };

  const handleClosePopup = () => {
    setSelectedService(null);
  };

  return (
    <section className="services-section">
      <h2>Nossos Serviços</h2>
      <div className="services-grid">
        {servicesData.map((service, index) => (
          <div key={index} className="service-card">
            <div className="service-icon">{service.icon}</div>
            <div className="service-text">
              <h3>{service.title}</h3>
              <p>{service.description}</p>
            </div>
            <button className="learn-more-btn" onClick={() => handleCardClick(service)}>
              Saiba Mais
            </button>
          </div>
        ))}
      </div>

      {selectedService && (
        <div className="service-popup">
          <div className="service-popup-content">
            <button className="close-popup" onClick={handleClosePopup}>X</button>

            {/* Imagem do Serviço no Popup */}
            <img src={selectedService.image} alt={selectedService.title} className="popup-image" />

            <h3>{selectedService.title}</h3>
            <div className="popup-section">
              <h4>O que é?</h4>
              <p>{selectedService.longDescription}</p>
            </div>

            <div className="popup-section">
              <h4>Por que você precisa?</h4>
              <p>{selectedService.whyYouNeed}</p>
            </div>

            <div className="popup-buttons">
              <button className="popup-button popup-close" onClick={handleClosePopup}>
                Deixar para meu Concorrente
              </button>
              <a href="https://wa.me/5521936185311" className="popup-button popup-whatsapp" target="_blank" rel="noopener noreferrer">
                Aproveitar essa Oportunidade
              </a>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Services;
