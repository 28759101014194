import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaLink, FaQrcode, FaTools, FaLaptop, FaTimes } from 'react-icons/fa'; // Ícone de "X" adicionado
import './Header.css';
import logo from '../../assets/logo/UpCo-Horizontal_ - line.png';
import underConstructionImage from '../../assets/images/vetores/Construction-amico.png'; // Imagem de "Em construção"

const Header: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSolutionsOpen, setIsSolutionsOpen] = useState(false); // Estado para controlar o dropdown
  const [isPopupOpen, setIsPopupOpen] = useState(false); // Estado para controlar o popup
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    if (!isMenuOpen) setIsSolutionsOpen(false); // Fecha o submenu ao abrir o menu mobile
  };

  const toggleSolutions = () => {
    setIsSolutionsOpen(!isSolutionsOpen); // Alterna o submenu ao clicar
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
    setIsSolutionsOpen(false); // Fecha tudo ao fechar o menu principal
  };

  const openPopup = () => {
    setIsPopupOpen(true); // Abre o popup
  };

  const closePopup = () => {
    setIsPopupOpen(false); // Fecha o popup
  };

  return (
    <>
      <header className="header">
        <div className="logo">
          <Link to="/" onClick={closeMenu}>
            <img src={logo} alt="UpdateCommit Logo" className="logo-img" />
          </Link>
        </div>

        {/* Navegação padrão para desktop */}
        <nav className="nav-menu">
          <ul>
            <li>
              <Link to="/" className={location.pathname === "/" ? "active" : ""} onClick={closeMenu}>
                Home
              </Link>
            </li>
            <li className="solutions-menu" onClick={toggleSolutions}>
              <span className="solutions-link">
                Soluções
              </span>
              {isSolutionsOpen && (
                <div className="solutions-dropdown">
                  <div className="solutions-column">
                    <h4>Free</h4>
                    <ul>
                      <li>
                        <Link to="/free-tools/whatsapp-link-generator" className="subitens" onClick={closeMenu}>
                          <FaLink className="icon" /> Gerador de Link de WhatsApp
                        </Link>
                      </li>
                      <li>
                        <Link to="/free-tools/qr-code-generator" className="subitens" onClick={closeMenu}>
                          <FaQrcode className="icon" /> Gerador de QR Code
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="solutions-column">
                    <h4>Soluções</h4>
                    <ul>
                      <li>
                        <FaTools className="icon" /> Em Breve...
                      </li>
                    </ul>
                  </div>
                  <div className="solutions-column">
                    <h4>Customizadas</h4>
                    <ul>
                      <li>
                        <Link to="/custom-solutions" onClick={closeMenu}>
                          <FaLaptop className="icon" /> Soluções Customizadas
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </li>
            <li>
              <Link to="/about" className={location.pathname === "/about" ? "active" : ""} onClick={closeMenu}>
                Sobre
              </Link>
            </li>
          </ul>
        </nav>

        {/* Botão para abrir o popup */}
        <button className="cta" onClick={openPopup}>Acessar Conta</button>

        {/* Ícone de hambúrguer para mobile */}
        <div className={`hamburger ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
      </header>

      {/* Menu lateral para mobile */}
      <div className={`mobile-menu ${isMenuOpen ? "open" : ""}`}>
        <ul>
          <li>
            <Link to="/" className={location.pathname === "/" ? "active" : ""} onClick={closeMenu}>
              Home
            </Link>
          </li>
          <li>
            <span onClick={toggleSolutions} className="solutions-link">
              Soluções
            </span>
            {isSolutionsOpen && (
              <ul className="submenu">
                <h4>Free</h4>
                <li>
                  <Link to="/free-tools/whatsapp-link-generator" onClick={closeMenu}>
                    <FaLink /> Gerador de Link de WhatsApp
                  </Link>
                </li>
                <li>
                  <Link to="/free-tools/qr-code-generator" onClick={closeMenu}>
                    <FaQrcode /> Gerador de QR Code
                  </Link>
                </li>
                <h4>Soluções</h4>
                <li>
                  <p>Em Breve...</p>
                </li>
                <h4>Customizadas</h4>
                <li>
                  <Link to="/custom-solutions" onClick={closeMenu}>
                    <FaTools /> Soluções Customizadas
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li>
            <Link to="/about" className={location.pathname === "/about" ? "active" : ""} onClick={closeMenu}>
              Sobre
            </Link>
          </li>
        </ul>
      </div>

      {/* Popup */}
      {isPopupOpen && (
        <div className="popup-backdrop" onClick={closePopup}>
          <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <button className="popup-close-construcao" onClick={closePopup}>
              <FaTimes />
            </button>
            <div className="popup-left">
              <h2>Em Construção</h2>
              <div className="progress-bar">
                <div className="progress" style={{ width: '35.7%' }}></div>
              </div>
            </div>
            <div className="popup-right">
              <img src={underConstructionImage} alt="Em construção" className="popup-image-construcao" />
            </div>
          </div>
        </div>
      )}

      {/* Backdrop para fechar o menu ao clicar fora */}
      {isMenuOpen && <div className="backdrop" onClick={closeMenu}></div>}
    </>
  );
};

export default Header;
