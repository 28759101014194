import React from 'react';
import './Hero.css';

const Hero: React.FC = () => {
  return (
    <section className="hero-section">
      <div className="circle-left"></div> {/* Círculo à esquerda */}
      <h1 className="hero-title">
        Soluções inovadoras em <span className="highlight-purple">programação</span> e <span className="highlight-blue">automação</span> para o seu negócio.
      </h1>
      <p className="hero-subtitle">
        Ajudamos sua empresa a crescer com desenvolvimento de software, gestão de tráfego e otimização de processos.
      </p>
      <button className="cta-button">Solicitar Demonstração</button>
      <div className="circle-right"></div> {/* Círculo à direita */}
    </section>
  );
};

export default Hero;
