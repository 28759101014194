import React from 'react';
import './Features.css';
import { FaCode, FaProjectDiagram, FaBullhorn, FaChartLine, FaCogs } from 'react-icons/fa'; 

const Features: React.FC = () => {
  return (
    <section className="features-section">
      <h2>Nossas Especialidades</h2>
      <div className="features-grid">
        <div className="feature-item">
          <div className="icon-circle">
            <FaCode className="feature-icon" /> {/* Ícone de programação */}
          </div>
          <h3>Programação</h3>
          <p>Desenvolvimento de soluções completas, desde landing pages até sistemas robustos e complexos, utilizando as melhores práticas em engenharia de software.</p>
        </div>

        <div className="feature-item">
          <div className="icon-circle">
            <FaProjectDiagram className="feature-icon" /> {/* Ícone de integração */}
          </div>
          <h3>Integração</h3>
          <p>Integração eficiente entre sistemas e plataformas, garantindo a troca de dados segura e a automação de processos empresariais.</p>
        </div>

        <div className="feature-item">
          <div className="icon-circle">
            <FaBullhorn className="feature-icon" /> {/* Ícone de tráfego pago */}
          </div>
          <h3>Gestão de Tráfego Pago</h3>
          <p>Otimização de campanhas, aumento de conversões e gestão eficiente de anúncios em Google Ads, Facebook Ads e outras plataformas.</p>
        </div>

        <div className="feature-item">
          <div className="icon-circle">
            <FaChartLine className="feature-icon" /> {/* Ícone de dashboard */}
          </div>
          <h3>Criação de Dashboards</h3>
          <p>Desenvolvimento de dashboards interativos para visualização e acompanhamento em tempo real dos principais KPIs do seu negócio.</p>
        </div>

        <div className="feature-item">
          <div className="icon-circle">
            <FaCogs className="feature-icon" /> {/* Ícone de automação */}
          </div>
          <h3>Automação de Processos</h3>
          <p>Implementação de soluções automatizadas para otimização de fluxos de trabalho, reduzindo custos e aumentando a eficiência.</p>
        </div>
      </div>
    </section>
  );
};

export default Features;
